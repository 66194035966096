<script setup lang="ts">
import type { Branches } from "~/types/directus-collections.d.ts";
const route = useRoute();

const onDashboard = computed(() => {
  return route.name === "admin-groups-slug";
});

defineProps<{
  branch: Branches | null;
}>();

const { data: page } = await useAsyncData(() =>
  useDirectus(
    readItems("generic_pages", {
      fields: ["*"],
      filter: {
        status: { _eq: "published" },
        slug: { _eq: "files" },
      },
    })
  ).then((results) => (results.length ? results[0] : null))
);

const sidebarLinks = [
  {
    name: "Member Contacts",
    to: "admin-groups-slug-members",
    hasSlug: true,
  },
  {
    name: "Group Emails",
    to: "admin-groups-slug-emails",
    hasSlug: true,
  },
  {
    name: "News",
    to: "admin-groups-slug-news",
    hasSlug: true,
  },
  {
    name: "Events",
    to: "admin-groups-slug-events",
    hasSlug: true,
  },
  {
    name: "Group Details",
    to: "admin-groups-slug-group-details",
    hasSlug: true,
  },
  {
    name: "Group Settings",
    to: "admin-groups-slug-group-settings",
    hasSlug: true,
  },
  {
    name: "Gallery",
    to: "admin-groups-slug-gallery",
    hasSlug: true,
  },
  {
    name: "Files for Members",
    to: "admin-groups-slug-files",
    hasSlug: true,
  },
];

if (page.value) {
  sidebarLinks.push({
    name: "Leader's Toolbox",
    to: "files",
    hasSlug: false,
  });
}

const slug = useBranchSlug();
</script>

<template>
  <div
    v-if="slug"
    class="order-1 col-span-12 mt-10 flex h-fit flex-col rounded-lg bg-primary md:-order-1 md:col-span-3 md:ml-0 md:mr-8 md:mt-0"
  >
    <NuxtLink
      v-for="(link, index) of sidebarLinks"
      :key="index"
      :to="link.hasSlug ? { name: link.to, params: { slug } } : { path: '/' + link.to }"
      class="pl-5 pt-10 text-sm font-semibold uppercase text-white transition after:mt-10 after:block hover:text-secondary"
      :class="index !== sidebarLinks.length - 1 ? 'after:h-[2px] after:w-24 after:bg-white/60' : ''"
    >
      {{ link.name }}
    </NuxtLink>
    <NuxtLink
      v-if="!onDashboard"
      class="rounded-b-lg bg-accentDark py-10 pl-5 font-semibold text-white transition hover:text-secondary"
      :to="{
        name: 'admin-groups-slug',
        params: { slug: branch?.slug },
      }"
    >
      <font-awesome-icon icon="fa-solid fa-arrow-left" icon-size="xl" class="mr-2 text-secondary"></font-awesome-icon>
      Back to group
    </NuxtLink>
  </div>
</template>

<style scoped>
.router-link-active {
  color: theme(colors.secondary);
}
</style>
